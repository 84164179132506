import React, { Component } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import BannerImage from '../components/elements/BannerImage'
import banner from '../images/slicing/home/banner.png'
class NurseioContainer extends Component {
    render() {
        return (
            <>
                <BannerImage
                    activeTab="Case Study"
                    bannerImage={banner}
                    text="Intelligent Cloud platform for healthcare professionals with omnichannel experience" />
                <Container fluid>
                    <Container className="py-5 border-bottom">
                        <Row>
                            <Col>
                                <h3 className="font-weight-bold">
                                    <span style={{ borderBottom: '2px', borderBottomColor: '#eebb6b', borderBottomStyle: 'solid' }}>The</span> Customer
                                    </h3>
                                <p className="industries-paragrapgh pt-3">
                                Nurseio is one of large healthcare professional 
                                registry in US catering to hospitals, clinics and 
                                senior patient homes for their on-demand healthcare professional needs.
                            </p>
                            <p className="industries-paragrapgh pt-3">
                            Healthcare is a business which is very heavily regulated and involves verification
                             of credentials of healthcare professionals, their certifications,
                              permits in real time. Also during Covid due to heavy pressure on
                               entire healthcare ecosystem it became extremely important to have an ability to respond quickly.
                            </p>
                            </Col>
                            <Col>
                                <h3 className="font-weight-bold">
                                    <span
                                        style={{ borderBottom: '2px', borderBottomColor: '#eebb6b', borderBottomStyle: 'solid' }}>Key</span> Information
                                    </h3>
                                <Container className="pt-3">
                                    <Row>
                                        <Col className="standard-background mt-2 p-3">
                                            <h3>Platform Scale</h3>
                                            <p>SaaS platform serving 100+ facilities, 1500+ healthcare professionals and 2000+ shifts.</p>
                                        </Col>
                                        <Col className="standard-background ml-2 mt-2 p-3">
                                            <h3>Intelligent Automation</h3>
                                            <p>Automated matching of shifts with healthcare professional</p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="standard-background mt-2 p-3">
                                        <h3>GTEN Role</h3>
                                            <p>Platform Development</p>
                                        </Col>
                                        <Col className="standard-background ml-2 mt-2 p-3">
                                        <h3>Technology</h3>
                                            <p>ReactJS, Ruby on Rails, Heroku</p>
                                        </Col>
                                    </Row>
                                </Container>
                            </Col>
                        </Row>
                    </Container>

                    <Container className="py-5 border-bottom">
                        <Row>
                            <Col>
                                <h3 className="font-weight-bold">
                                    <span
                                        style={{ borderBottom: '2px', borderBottomColor: '#eebb6b', borderBottomStyle: 'solid' }}>Key</span> Challenge
                                    </h3>
                                <p className="industries-paragrapgh pt-3">
                                The existing process completely manual with steps as follows:
                                <br/></p>
                                <p className="industries-paragrapgh">
                                •  Nurseio sales person would contact the staffing manager in a facility to gather needs.
                            </p>
                                <p className="industries-paragrapgh">
                                • Needs once identified will be shared with Nurseio staffer who will then start texting/calling healthcare professionals who fit the need.
                            </p>
                                <p className="industries-paragrapgh">
                                • Once confirmation is received from healthcare professional, their documents will be shared with facility for confirmation.
                                
                            </p>
                                <p className="industries-paragrapgh">
                                • Once confirmed by facility, Nurseio staffer would confirm healthcare professional.
                            </p>
                                <p className="industries-paragrapgh">
                                • Post shift invoice is raised at end of week and healthcare professional is paid after getting feedback from facility
                            .
                            </p>
                            <p className="industries-paragrapgh">
                            When carefully analysed the existing process, we figure 
                            out the following issues which needed to fix into the process:<br/>
                            </p>
                            
                                        <p className="industries-paragrapgh">•  Entire process had multiple points of failures.</p>
                                        <p className="industries-paragrapgh">•  Unable to scale.</p>
                                        <p className="industries-paragrapgh">•  High management cost.</p>
                                        <p className="industries-paragrapgh">•  Offline dependencies.</p>
                                   
                            </Col>
                            <Col>
                                <h3 className="font-weight-bold">
                                    <span
                                        style={{ borderBottom: '2px', borderBottomColor: '#eebb6b', borderBottomStyle: 'solid' }}>GTEN</span> Solution
                                    </h3>
                                <p className="industries-paragrapgh pt-3">
                                Our solution was based on solving three crucial problems in the existing process.
                            </p>
                            
                            
                            <p className="industries-paragrapgh">•  Automate the entire workflow using a responsive SaaS
                             platform hosted on Heroku developed using ReactJS and Ruby on Rails.</p>
                                        <p className="industries-paragrapgh">•  Building intelligent automation 
                                        for matching need with availability of healthcare professional considering 
                                        multiple factors.
                            .</p>
                                        <p className="industries-paragrapgh">•  Real time confirmation of shift feedback 
                                        and pay-out to healthcare professional using integration wit Stripe.
                            .</p>
                                        <p className="industries-paragrapgh">•  Real time notification to all stakeholders at every touchpoint.</p>

                                <p className="industries-paragrapgh">
                                The very first version of the solution was to have EPCIS developed
                                 and set up for all the involved parties and then move their 
                                 transaction events into distributed ledgers on Blockchain.
                            </p>
                                <p className="industries-paragrapgh">
                               <span className="text-standard"> The solution is divided into three major parts:</span><br/>
                            </p>
                                <p className="industries-paragrapgh">
                                • <b>SaaS Platform</b> — cloud-based web application.<br/>
                                • <b>Omnichannel Experience</b> — Mobile application to continue where you left.<br/>
                                • <b>Reporting & Analytics</b> — Identify revenue loss and expansion opportunities.
                            </p>
                            </Col>

                        </Row>

                    </Container>
                    <Container className="py-5">
                        <Row>
                            <Col>
                                <h3 className="font-weight-bold">
                                    <span
                                        style={{ borderBottom: '2px', borderBottomColor: '#eebb6b', borderBottomStyle: 'solid' }}>Benefits</span> Achieved
                                    </h3>
                                <p className="industries-paragrapgh pt-3">
                                Following benefits were delivered to customer:
                                </p>
                                
                                <ul>
                                        <li> Increased revenue by 350%.</li>
                                        <li> Scaled operations 5 times but reduced operations cost by 80%.</li>
                                        <li>Verification and confirmation of healthcare professionals in real-time by integration with nursing boards and certification authority
                                .</li>
                                        <li>Geotagging of facilities and healthcare professionals to avoid any disputeson shifts completed.
                                .</li>
                                    </ul>
                                
                            </Col>


                        </Row>
                    </Container>
                    <hr />
                </Container>

            </>
        )
    }
}

export default NurseioContainer