import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/seo"
import NurseioContainer from "../containers/NurseioContainer"

const Nurseio = (props) => (
  <Layout 
  pageInfo={{ pageName: "Nurseio" }} 
  styleClass="home-page" 
  location={'/Nurseio/'}>
    <SEO title="Nurseio" />
    <NurseioContainer />
  </Layout>
)

export default Nurseio
